import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyHeadingWithIllustrations } from "../../../slices/PageDataBodyHeadingWithIllustrations"

type Card = React.ComponentPropsWithoutRef<
	typeof PageDataBodyHeadingWithIllustrations
>["cards"][number]

const card = (data: Partial<Card> = {}): Card => {
	return {
		heading: lorem.words(3),
		imgUrl:
			"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/a43c32e3-f8c9-4449-8cb5-a2f54a169317_2_icon-fist-bump.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
		imgAlt: "",
		imgDimensions: { width: 150, height: 150 },
		copy: [
			{
				type: "paragraph",
				text: lorem.sentences(2),
				spans: [],
			},
		],
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyHeadingWithIllustrations
				heading={lorem.words(5)}
				cards={[
					card({
						imgUrl:
							"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/a43c32e3-f8c9-4449-8cb5-a2f54a169317_2_icon-fist-bump.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
					}),
					card({
						imgUrl:
							"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/345ebe80-fcb5-4467-8024-2e2b92dc6362_2_icon-bike.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
					}),
					card({
						imgUrl:
							"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/1470c0d5-ac15-4a75-bda1-8339b3ba6499_2_icon-piggy.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
					}),
				]}
			/>
		</CmsGuideLayout>
	)
}

export default Component
